/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  // independence-day-2023
  {   
    description:
      'Investiture 2024',
    href : '/investiture-2024'
 
  },
  {   
    description:
      'Orientation Program',
    href : '/orientation-programme-2024'
 
  },
  {   
    description:
      'International Yoga Day',
    href : '/yoga-day-2024'
 
  },
  {   
    description:
      'School Council 2024',
    href : '/school-council-2024'
 
  },
  {   
    description:
      'Goonj Event 2024',
    href : '/goonj-2024'
 
  },
  {   
    description:
      'No Bag Day',
    href : '/no-bag-day-2024'
 
  },
  {   
    description:
      'Dr. Maria Montessori Day',
    href : '/maria-montessori-day'
 
  },
  {   
    description:
      'Teachers Day',
    href : '/teachers-day-preprimary-2024'
 
  },

  {   
    description:
      'Teachers Day Class 6-12',
    href : '/teachers-day-6-12-2024'
 
  },
  {   
    description:
      'Onam Day Celebrations class 1-5',
    href : '/onam-1-5-2024'
    
 
  },
  {   
    description:
      'Scintilla Of Art',
    href : '/scintilla-of-art-2024'
    
 
  },
  {   
    description:
      'No Bag Day Class 6-8',
    href : '/no-bag-day-class-6-8-2024'
    
 
  },
  {   
    description:
      'Dasara Celebration Preprimary',
    href : '/dasara-celebration-preprimary-2024'
    
 
  },
  {   
    description:
      'Science Exhibition Class 6-8',
    href : '/science-exhibition-class-6-8-2024'
 
  },
  {   
    description:
      'Bagless Day Class 6-8',
    href : '/bagless-day-class-6-8-2024'
 
  },
  {   
    description:
      'Rajyotsava Day Class 1-8',
    href : '/rajyotsava-day-class-1-8-2024'
 
  },
  {   
    description:
      'Children’s Day 2024',
    href : '/childrens-day-2024'
 
  },
  {   
    description:
      'Aagaman 2024',
    href : '/aagaman-2024'
 
  },
  {   
    description:
      'Elevate 2024',
    href : '/elevate-2024'
 
  },
  {   
    description:
      'Sansad 2024',
    href : '/sansad-2024'
 
  },
  {   
    description:
      'Christmas Celebration 2024',
    href : '/christmas-celebration-2024'
 
  },
  {   
    description:
      'Graduation Day 2024',
    href : '/graduation-day-2024-25'
 
  },
  {   
    description:
      'Udaan 2024',
    href : '/udaan-2024-25'
 
  },
  {   
    description:
      'Valedictory Ceremony 2024',
    href : '/valedictory-ceremony-2024'
 
  },
  {   
    description:
      'Class 10 Elucidation 2024',
    href : '/class-10-elucidation-2025'
 
  },
  {   
    description:
      'Fusion Fest 2024',
    href : '/fusion-fest-2024'
 
  },
  
];

const Sidebar2024 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        School Programs 2024-25
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sidebar2024;
